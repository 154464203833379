import { AddIcon } from "@chakra-ui/icons";
import { Box, Flex, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";

export const TagsSection = ({ tags }: any) => (
  <Box>
    <Text fontWeight='bold' mb={1}>Tags</Text>
    {Array.isArray(tags) && tags.length > 0 ? (
      <Flex flexWrap='wrap' gap={2}>
        {tags.map((tag: string, index: number) => (
          <Tag key={index} variant='subtle' colorScheme='cyan'>
            <TagLeftIcon boxSize='12px' as={AddIcon} />
            <TagLabel>{tag}</TagLabel>
          </Tag>
        ))}
      </Flex>
    ) : (
      <Text>No Tags selected</Text>
    )}
  </Box>
);
