import { AspectRatio, Box, Center, Text } from "@chakra-ui/react";

export const LocationSection = ({ place, address, lat, lng }: any) => (
  <Box>
    <Text fontWeight={"bold"}> Location </Text>
    <Text> {place} </Text>
    <Text> {address} </Text>
    {lat && lng ? (
      <AspectRatio ratio={16 / 9}>
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAXTp_NHivJBBs5QoXIAME-lgFp7dtwnvg&q=${address}&center=${lat},${lng}`}
        ></iframe>
      </AspectRatio>
    ) : (
      <Center h='300px' bg='gray.200'>
        <Text fontSize='xl'>No map location</Text>
      </Center>
    )}
  </Box>
);
