import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";

import { useState } from "react";
import { PersonFormData } from "../../../utilities/type-defs";
export default function AttendeeForm({
  event,
  onFormChange,
  index,
  errorData,
}) {
  const [formData, setFormData] = useState<PersonFormData>({
    name: "",
    email: "",
    phone: "",
    ticketType: "",
  });

  const errors = errorData[index];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Call the parent component's form change function
    onFormChange(index, name, value); // Pass the index directly
  };

  return (
    <Box key={"people_form_" + event._id} as={"form"} mt={2}>
      <Stack
        spacing={4}
        borderWidth='1px'
        py='6'
        px='4'
        // mb='2'
        borderRadius='lg'
        color={"black.700"}
        borderStyle='dotted'
      >
        <Text mb={2} fontWeight='bold'>
          Ticket {index + 1}
        </Text>
        <FormControl isInvalid={!!errors?.ticketType}>
          {/* <FormLabel>Ticket Type:</FormLabel> */}
          <RadioGroup>
            <Stack direction='row'>
              {event.ticketTypes
                .slice()
                .sort((a, b) => a.price - b.price)
                .map((items) => (
                  <Radio
                    onChange={handleInputChange}
                    key={items.name}
                    value={items.price}
                    name='ticketType'
                  >
                    {items.name}
                  </Radio>
                ))}
            </Stack>
          </RadioGroup>
          <FormErrorMessage>{errors?.ticketType}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.name}>
          {/* <FormLabel>Full Name:</FormLabel> */}
          <Input
            name='name'
            placeholder='First And Last Name'
            value={formData.name}
            onChange={handleInputChange}
          />
          <FormErrorMessage>{errors?.name}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.email}>
          {/* <FormLabel>Email:</FormLabel> */}
          <Input
            name='email'
            placeholder='lastname.firstname@hotmail.com'
            value={formData.email}
            onChange={handleInputChange}
          />
          <FormErrorMessage>{errors?.email}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.phone}>
          {/* <FormLabel>Phone:</FormLabel> */}
          <Input
            name='phone'
            placeholder='+61 123 456 789'
            value={formData.phone}
            onChange={handleInputChange}
          />
          <FormErrorMessage>{errors?.phone}</FormErrorMessage>
        </FormControl>
      </Stack>
    </Box>
  );
}
