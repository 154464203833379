import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Messages: React.FC = () => {
    return (
        <Box>
            <Text fontSize="2xl">See Your Messages</Text>
        </Box>
    );
}

export default Messages;
