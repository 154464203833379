import { Box, Button, Text } from "@chakra-ui/react";
import { CSSProperties } from "react";

const DescriptionSection = ({ descriptionReadMore, eventDescription }: any) => (
  <Box>
    <Text fontWeight={"bold"}> Description</Text>
    <Text
      style={descriptionReadMore.isOpen ? undefined : paragraphStyles}
      ref={
        descriptionReadMore.contentRef as React.RefObject<HTMLParagraphElement>
      }
    >
      {descriptionReadMore.content}
    </Text>
    {descriptionReadMore.showReadMoreButton && (
      <Button
        variant='link'
        color='blue.500'
        onClick={() => descriptionReadMore.toggleReadMore()}
      >
        {descriptionReadMore.isOpen ? "Read Less" : "Read More"}
      </Button>
    )}
  </Box>
);
const paragraphStyles: CSSProperties = {
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  display: "-webkit-box",
};

export default DescriptionSection;
