import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { CREATE_PAYMENT_INTENT } from "../../utilities/stripe-gql";
import { FormErrors, PersonFormData } from "../../utilities/type-defs";
import {
  AUTHORIZATION_BEARER,
  GET_USER_ID_FROM_LOGIN,
} from "../../utilities/users-gql";
import {
  calculateTotalAmount,
  isValidEmail,
  isValidPhone,
} from "../../utilities/utils";
import LoginForm from "../Auth/Login/LoginForm";
import useAuthModals from "../Auth/useAuthModals";
import AppearPeopleForm from "./components/AttendeeForm";
import EventDetailsCard from "./components/EventDetailsCard";
import CartItem from "./components/FinalPreviewTicketItem";
import PricingItem from "./components/PricingItem";
import StripeCheckout from "./components/StripeCheckout";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_test_8QFahohYbJA91bfrDsDwWYAV007koQfDjt"
);

export default function Purchase() {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  const bearer = AUTHORIZATION_BEARER();
  const userId = GET_USER_ID_FROM_LOGIN();

  const options = {
    context: {
      ...bearer,
    },
    variables: {
      userId: userId,
    },
  };

  // if (!userId) navigate("/login");
  let backgroundColour = useColorModeValue("white", "gray.700");
  let event;
  if (location.state) {
    event = location.state.event;
  } else {
    window.location.href = "/explore";
  }
  const { register, login, forgotPassword } = useAuthModals();

  const [amountOfTickets, setAmountOfTickets] = React.useState(1);
  const [peopleData, setPeopleData] = useState<PersonFormData[]>([
    {
      name: "",
      email: "",
      phone: "",
      ticketType: "",
    },
  ]);
  const [clientSecret, setClientSecret] = useState("");
  const [isCheckout, setIsCheckout] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [errorData, setErrorData] = useState<FormErrors[]>([]);

  const [createPaymentIntentQuery, createPaymentIntentData] = useMutation(
    CREATE_PAYMENT_INTENT,
    { ...options }
  );

  const stripeAppearance = {
    theme: "stripe",
  };

  const stripeOptions = {
    clientSecret,
    stripeAppearance,
  };

  useEffect(() => {
    const totAmt = calculateTotalAmount(peopleData);
    setTotalAmount(totAmt);
  }, [peopleData]);

  const handleCheckout = async (e) => {

    try {
      e.preventDefault();

      // Perform form validation here
      if (validateForm()) {
        console.log("Form is invalid");
        return;
      } else {
        console.log("Form is valid");
        if (
          !userId ||
          userId === null ||
          userId === undefined ||
          bearer === null ||
          bearer === undefined
        ) {
          login.open();
          return;
        }
      }

      setIsCheckout(true);

      //don't bother if we don't have anything to pay for
      if (totalAmount > 0) {
        const { data } = await createPaymentIntentQuery({
          variables: {
            amount: (totalAmount * 100).toString(),
            eventId: event._id,
          },
        });

        if (data.createPaymentIntent === "") {
          toast({
            title: "Payment Failed",
            description:
              "An error with the server has occurred. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setIsCheckout(false);
          return;
        }
        let paymentIntent;
        try {
          paymentIntent = JSON.parse(data.createPaymentIntent);
        } catch (error) {
          console.error("Error parsing payment intent:", error);
          return;
        }

        if (paymentIntent && paymentIntent.client_secret) {
          setClientSecret(paymentIntent.client_secret);
        } else {
          console.error("Invalid payment intent:", paymentIntent);
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };

  const validateForm = () => {
    const formValidationErrors: { personIndex: number; errors: FormErrors }[] =
      [];

    // Validate each person's form data
    peopleData.forEach((personData, index) => {
      const errors: FormErrors = {};

      if (!personData.name) {
        errors.name = `Name is required for Person ${index + 1}.`;
      }

      if (!personData.email) {
        errors.email = `Email is required for Person ${index + 1}.`;
      } else if (!isValidEmail(personData.email)) {
        errors.email = `Invalid email address for Person ${index + 1}.`;
      }

      if (!personData.phone) {
        errors.phone = `Phone is required for Person ${index + 1}.`;
      } else if (!isValidPhone(personData.phone)) {
        errors.phone = `Invalid phone number for Person ${index + 1}.`;
      }

      if (!personData.ticketType) {
        errors.ticketType = `Ticket Type is required for Person ${index + 1}.`;
      }

      if (Object.keys(errors).length > 0) {
        formValidationErrors.push({ personIndex: index, errors });
      }

      setErrorData((prevData) => {
        const updatedData = [...prevData];
        if (!updatedData[index]) {
          updatedData[index] = {};
        }
        updatedData[index] = errors;
        return updatedData;
      });
    });

    // Return the formValidationErrors array, which will be empty if there are no errors
    return formValidationErrors.length > 0 ? formValidationErrors : null;
  };

  const debouncedValidateForm = debounce(validateForm, 500); // Adjust the delay as needed (e.g., 500ms)

  const handleFormChange = (
    personIndex: number,
    fieldName: string,
    fieldValue: string
  ) => {
    setPeopleData((prevData) => {
      const updatedData = [...prevData];
      if (!updatedData[personIndex]) {
        updatedData[personIndex] = {};
      }
      updatedData[personIndex][fieldName] = fieldValue;
      return updatedData;
    });
    debouncedValidateForm();
  };

  const handleChange = (number) => {
    setAmountOfTickets(number);
    if (number > peopleData.length) {
      // Add empty form data for the newly added components
      setPeopleData((prevData) => [
        ...prevData,
        ...Array.from({ length: number - peopleData.length }, () => ({
          name: "",
          email: "",
          phone: "",
          ticketType: "",
        })),
      ]);
    } else if (number < peopleData.length) {
      // Remove the corresponding form data for the removed components
      setPeopleData((prevData) => prevData.slice(0, number));
    }
  };

  if (createPaymentIntentData.loading) {
    return (
      <Center mt={"20%"}>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='purple.500'
          size='xl'
        />
      </Center>
    );
  }

  if (createPaymentIntentData.error) {
    return <div>Error: {createPaymentIntentData.error.message}</div>;
  }

  return (
    <Container maxW='container.xl' py={12}>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Breadcrumbs
          items={[
            {
              text: "Explore",
              href: "/",
            },
            {
              text: event.title,
              onClick: (e) => {
                e.preventDefault();
                window.history.back();
              },
            },
            {
              text: "Buy Ticket",
              isCurrentPage: true,
              href: "#",
            },
          ]}
          additionalProps={{ pl: 4, gridColumn: "1 / -1" }}
        />
        {/* {isLoginOpen && <LoginForm login={login} register={register} forgotPassword={forgotPassword}/>} */}
        <LoginForm
          login={login}
          register={register}
          forgotPassword={forgotPassword}
        />

        {/* Left side */}
        <EventDetailsCard event={event} />

        {/* Right side */}
        <Box bg={backgroundColour} p={8} rounded='md' shadow='lg'>
          <Heading size='md' fontWeight='bold'>
            Get Your Tickets
          </Heading>
          {(() => {
            if (!isCheckout) {
              return (
                <Checkout
                  event={event}
                  amountOfTickets={amountOfTickets}
                  peopleData={peopleData}
                  totalAmount={totalAmount}
                  handleChange={handleChange}
                  handleFormChange={handleFormChange}
                  errorData={errorData}
                  handleCheckout={handleCheckout}
                />
              );
            } else if (totalAmount < 1) {
              return (
                <FinalizeFree
                  event={event}
                  peopleData={peopleData}
                  clientSecret={clientSecret}
                  totalAmount={totalAmount}
                />
              );
            } else {
              return (
                <FinalizePaid
                  clientSecret={clientSecret}
                  event={event}
                  stripeOptions={stripeOptions}
                  peopleData={peopleData}
                  totalAmount={totalAmount}
                />
              );
            }
          })()}
        </Box>
      </SimpleGrid>
    </Container>
  );
}

const Checkout = ({
  event,
  amountOfTickets,
  peopleData,
  totalAmount,
  handleChange,
  handleFormChange,
  errorData,
  handleCheckout,
}) => (
  <>
    <Flex justifyContent='space-around' mb={5}>
      {event.ticketTypes
        .slice()
        .sort((a, b) => a.price - b.price) // Sort by price in ascending order
        .map((items, index) => (
          <PricingItem key={index} {...items} />
        ))}
    </Flex>

    <Flex justifyContent='space-between' alignItems='center' mb={5}>
      <FormControl id='number-of-tickets' flex='1'>
        <FormLabel fontWeight='bold' fontSize='lg'>
          Number of Tickets
        </FormLabel>
        <NumberInput
          maxW='100px'
          value={amountOfTickets}
          onChange={handleChange}
          min={1}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper bg='green.200' />
            <NumberDecrementStepper bg='pink.200' />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <Text fontWeight='bold' fontSize='lg'>
        Total Amount: ${totalAmount}
      </Text>
    </Flex>
    <Flex direction='column'>
      {peopleData.map((_, index) => (
        <Box mb={5} key={index}>
          <AppearPeopleForm
            key={index}
            event={event}
            onFormChange={handleFormChange}
            index={index}
            errorData={errorData}
          />
        </Box>
      ))}
    </Flex>

    <Center>
      <Button
        fontFamily={"heading"}
        w={{ lg: "lg" }}
        bgGradient='linear(to-r, red.400,pink.400)'
        color={"white"}
        _hover={{
          bgGradient: "linear(to-r, red.400,pink.400)",
          boxShadow: "xl",
        }}
        onClick={handleCheckout}
      >
        Check Out
      </Button>
    </Center>
  </>
);

const FinalizePaid = ({
  clientSecret,
  event,
  stripeOptions,
  peopleData,
  totalAmount,
}) => (
  <Elements stripe={stripePromise} options={stripeOptions}>
    <VStack align='start' spacing='4' mb={5}>
      <StripeCheckout
        clientSecret={clientSecret}
        event={event}
        peopleData={peopleData}
        isFree={false}
      />
      <Heading size='md'>Total price: ${totalAmount}</Heading>

      {peopleData.map((item, index) => (
        <CartItem key={index} index={index} item={item} />
      ))}
    </VStack>
  </Elements>
);

const FinalizeFree = ({ event, peopleData, clientSecret, totalAmount }) => (
  <>
    <VStack align='start' spacing='4' mb={5}>
      <StripeCheckout
        clientSecret={clientSecret}
        event={event}
        peopleData={peopleData}
        isFree={true}
      />
      <Heading size='md'>
        Total price: {totalAmount < 1 ? "Free" : "$" + totalAmount.toString()}
      </Heading>

      {peopleData.map((item, index) => (
        <CartItem key={index} index={index} item={item} />
      ))}
    </VStack>
  </>
);
