import { Box, HStack } from "@chakra-ui/react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

function SocialSharing({ url, windowWidth, windowHeight }) {
  const SHARE_MSG = `Check out this event I'm interested in! `;

  return (
    <Box>
      <HStack>
        <FacebookShareButton
          quote={SHARE_MSG}
          url={url}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>

        {/* For Messenger, when supported
      <FacebookMessengerShareButton title={message} url={url} windowWidth={windowWidth} windowHeight={windowHeight}>
      <FacebookMessengerIcon size={32} round={true} />
     </FacebookMessengerShareButton>
    */}

        <TwitterShareButton
          title={SHARE_MSG}
          url={url}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        >
          <TwitterIcon round={true} size={32} />
        </TwitterShareButton>

        <WhatsappShareButton
          title={SHARE_MSG}
          url={url}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        >
          <WhatsappIcon round size={32} />
        </WhatsappShareButton>
      </HStack>
    </Box>
  );
}

export default SocialSharing;
