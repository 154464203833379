import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Alert,
  AlertTitle,
  Button,
  Center,
  Heading,
  Link,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import QRCode from "qrcode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SEND_EMAIL } from "../../../utilities/email-gql";
import { CREATE_TICKET } from "../../../utilities/tickets-gql";
import { Event, PersonFormData } from "../../../utilities/type-defs";
import {
  AUTHORIZATION_BEARER,
  GET_USER_ID_FROM_LOGIN,
} from "../../../utilities/users-gql";
export default function StripeCheckout(props: {
  clientSecret: string;
  event: Event;
  peopleData: PersonFormData[];
  isFree: boolean;
}): JSX.Element {
  const toast = useToast();
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let stripe: Stripe | null = props.isFree ? null : useStripe();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let elements: StripeElements | null = props.isFree ? null : useElements();

  const bearer = AUTHORIZATION_BEARER();
  const userId = GET_USER_ID_FROM_LOGIN();

  const options = {
    context: {
      ...bearer,
    },
    variables: {
      userId: userId,
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentSuccessful, setpaymentSuccessful] = useState(false);

  const [createTicketMutation, createTicketMutationData] = useMutation(
    CREATE_TICKET,
    options
  );
  const [sendEmailMutation, sendEmailMutationData] = useLazyQuery(
    SEND_EMAIL,
    options
  );

  const handleCheckout = async (e) => {
    e.preventDefault();

    setIsPaymentProcessing(true);
    setIsLoading(true);

    if (!props.isFree) {
      //can't do anything without this!
      if (!stripe || !elements || paymentSuccessful) return;
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url:
            process.env.REACT_APP_STRIPE_REDIRECT_URL ||
            "http://localhost:3000/purchase/complete",
        },
        redirect: "if_required",
      });

      if (error) {
        setIsLoading(false);
        setIsPaymentProcessing(false);

        console.log(error);
        if (error.type === "card_error" || error.type === "validation_error") {
          toast({
            title: "Payment Error",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        } else {
          toast({
            title: "Payment Error",
            description: "An error occurred. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        }
      }

      if (paymentIntent) {
        switch (paymentIntent.status) {
          case "succeeded":
            toast({
              title: "Payment Successful",
              description: "Your payment was successful.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });

            //Our payment is successful
            setpaymentSuccessful(true);

            //We can now generate the tickets here
            for (const person of props.peopleData) {
              //@ts-ignore
              const ticketType = props.event.ticketTypes.find(
                (ticket) =>
                  parseInt(ticket.price) === parseInt(person.ticketType || "")
              );

              //for each person, we need to create a ticket
              const data = await createTicketMutation({
                variables: {
                  ticketInput: {
                    eventId: props.event._id,
                    userId: userId,
                    // @ts-ignore
                    ticketTypeId: ticketType._id,
                    ticketHolderName: person.name,
                    ticketHolderPhoneNumber: person.phone,
                    ticketHolderEmail: person.email,
                    clientSecret: props.clientSecret,
                    paymentIntentId: paymentIntent.id,
                  },
                },
              });

              if (data.data.createTicket) {
                //ticket ID
                const ticketId = data.data.createTicket._id;
                QRCode.toDataURL(
                  ticketId,
                  { type: "image/png" },
                  async (err, url) => {
                    console.log(url);

                    const message =
                      `<p>Thank you for purchasing a ticket to ${props.event.title}.</p>` +
                      `<p>Please find your QR Code for check-in attached to this email.</p>` +
                      `<img src='${url}' alt="QR Code for ${props.event.title} ticket" />`;

                    await sendEmailMutation({
                      variables: {
                        to: person.email,
                        subject: `Ticket for ${props.event.title}`,
                        message: message,
                      },
                    });
                  }
                );
              }
            }
            break;
          case "processing":
            toast({
              title: "Payment Processing}",
              description: "Your payment is being processed.",
              status: "warning",
              duration: 9000,
              isClosable: true,
            });
            break;
          case "requires_payment_method":
            toast({
              title: "Payment Error",
              description:
                "Your payment was not successful. Please try again later.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            break;
          default:
            toast({
              title: "Payment Error",
              description: "An error occurred. Please try again later.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            break;
        }
      }
    } else {
      //Our payment is successful
      setpaymentSuccessful(true);

      toast({
        title: "Payment Successful",
        description: "Your payment was successful.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      //We can now generate the tickets here
      for (const person of props.peopleData) {
        //@ts-ignore
        const ticketType = props.event.ticketTypes.find(
          (ticket) => parseInt(ticket.price) === parseInt(person.ticketType || "")
        );

        //for each person, we need to create a ticket
        const data = await createTicketMutation({
          variables: {
            ticketInput: {
              eventId: props.event._id,
              userId: userId,
              // @ts-ignore
              ticketTypeId: ticketType._id,
              ticketHolderName: person.name,
              ticketHolderPhoneNumber: person.phone,
              ticketHolderEmail: person.email,
            },
          },
        });

        if (data.data.createTicket) {
          //ticket ID
          const ticketId = data.data.createTicket._id;
          QRCode.toDataURL(
            ticketId,
            { type: "image/png" },
            async (err, url) => {
              console.log(url);

              const message =
                `<p>Thank you for purchasing a ticket to ${props.event.title}.</p>` +
                `<p>Please find your QR Code for check-in attached to this email.</p>` +
                `<img src='${url}' alt="QR Code for ${props.event.title} ticket" />`;

              await sendEmailMutation({
                variables: {
                  to: person.email,
                  subject: `Ticket for ${props.event.title}`,
                  message: message,
                },
              });
            }
          );
        }
      }
    }

    setIsPaymentProcessing(false);
    setIsLoading(false);
  };

  return (
    <div>
      <Heading mb={4} size='md' />
      {!paymentSuccessful ? (
        !props.isFree ? (
          <PaymentElement id='payment-element' options={{ layout: "tabs" }} />
        ) : (
          ""
        )
      ) : (
        <Alert status={"success"}>
          <AlertTitle>Success!</AlertTitle>Payment Successful. Please check your
          email.
        </Alert>
      )}

      <Center>
        {paymentSuccessful ? (
          <VStack spacing={4}>
            <Link href='/explore'>
              <Button
                disabled={!stripe || isLoading || isPaymentProcessing}
                fontFamily={"heading"}
                mt={8}
                w={{ lg: "lg" }}
                bgGradient='linear(to-r, red.400,pink.400)'
                color={"white"}
                _hover={{
                  bgGradient: "linear(to-r, red.400,pink.400)",
                  boxShadow: "xl",
                }}
              >
                Explore more events
              </Button>
            </Link>
            <Text>or</Text>
            <Button
              fontFamily={"heading"}
              w={{ lg: "lg" }}
              bgGradient='linear(to-r, red.400,pink.400)'
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, red.400,pink.400)",
                boxShadow: "xl",
              }}
              onClick={() => navigate(-1)} // Navigate back to the previous page
            >
              Go back to the event details
            </Button>
          </VStack>
        ) : (
          <Button
            disabled={!stripe || isLoading || isPaymentProcessing}
            fontFamily={"heading"}
            w={{ lg: "lg" }}
            bgGradient='linear(to-r, red.400,pink.400)'
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, red.400,pink.400)",
              boxShadow: "xl",
            }}
            onClick={handleCheckout}
          >
            {isLoading || isPaymentProcessing ? (
              <Spinner></Spinner>
            ) : !props.isFree ? (
              "Pay now"
            ) : props.peopleData.length > 1 ? (
              "Get my tickets!"
            ) : (
              "Get my ticket!"
            )}
          </Button>
        )}
      </Center>
    </div>
  );
}
