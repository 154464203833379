import { useDisclosure } from "@chakra-ui/react";

function useAuthModals() {
  const loginDisclosure = useDisclosure();
  const registerDisclosure = useDisclosure();
  const forgotPasswordDisclosure = useDisclosure();

  const openLogin = () => {
    registerDisclosure.onClose();
    forgotPasswordDisclosure.onClose();

    setTimeout(() => {
      loginDisclosure.onOpen();
    }, 10);
  };

  const openRegister = () => {
    loginDisclosure.onClose();
    forgotPasswordDisclosure.onClose();

    setTimeout(() => {
      registerDisclosure.onOpen();
    }, 10);
  };

  const openForgotPassword = () => {
    loginDisclosure.onClose();
    registerDisclosure.onClose();

    setTimeout(() => {
      forgotPasswordDisclosure.onOpen();
    }, 10);
  };

  return {
    login: {
      isOpen: loginDisclosure.isOpen,
      open: openLogin,
      close: loginDisclosure.onClose,
    },
    register: {
      isOpen: registerDisclosure.isOpen,
      open: openRegister,
      close: registerDisclosure.onClose,
    },
    forgotPassword: {
      isOpen: forgotPasswordDisclosure.isOpen,
      open: openForgotPassword,
      close: forgotPasswordDisclosure.onClose,
    },
  };
}

export default useAuthModals;
