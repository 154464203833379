import { Box, Button, Text } from "@chakra-ui/react";
import React, { CSSProperties } from "react";

const RefundSection = ({ refundReadMore }: any) => (
  <Box>
    <Text fontWeight='bold'>Refund Policy</Text>
    <Text
      style={refundReadMore.isOpen ? undefined : paragraphStyles}
      ref={refundReadMore.contentRef as React.RefObject<HTMLParagraphElement>}
    >
      {refundReadMore.content}
    </Text>
    {refundReadMore.showReadMoreButton && refundReadMore.isContentLoaded && (
      <Button
        variant='link'
        color='blue.500'
        onClick={() => refundReadMore.toggleReadMore()}
      >
        {refundReadMore.isOpen ? "Read Less" : "Read More"}
      </Button>
    )}
  </Box>
);
const paragraphStyles: CSSProperties = {
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  display: "-webkit-box",
};

export default RefundSection;
