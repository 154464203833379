import { useLazyQuery, useQuery } from "@apollo/client";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Container,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link as RouterLink } from "react-router-dom";
import EventCards from "../../components/EventCards";
import {
  GET_CATEGORIES,
  GET_EVENTS_FILTERED,
} from "../../utilities/events-gql";
import { Event } from "../../utilities/type-defs";
import { handleTimeFrameFilter } from "../../utilities/utils";
// @ts-ignore
import DatePicker from "react-datepicker";
import "./DatePickerStyles.css";

const Explore: React.FC = () => {
  const categoryData = useQuery(GET_CATEGORIES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const today = new Date();

  const [searchQuery, setSearchQuery] = useState("");
  const [localSearchQuery, setLocalSearchQuery] = useState("");
  const debouncedSave = useCallback(
    _.debounce((nextValue) => setSearchQuery(nextValue), 200),
    []
  );

  const [timeFrame, setTimeFrame] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [sortBy, setSortBy] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isPaidOrFree, setIsPaidOrFree] = useState("");

  const [getFilteredEvents, { loading, data, error }] = useLazyQuery(
    GET_EVENTS_FILTERED,
    {
      variables: {
        filter: {
          category: selectedCategory,
          sortBy: sortBy,
          isPaidOrFree: isPaidOrFree,
          searchQuery: searchQuery,
          startDate: startDate ? startDate.toISOString() : null,
        },
      },
    }
  );

  useEffect(() => {
    getFilteredEvents({
      variables: {
        filter: {
          category: selectedCategory,
          sortBy: sortBy,
          isPaidOrFree: isPaidOrFree,
          searchQuery: searchQuery,
          startDate: startDate ? startDate.toISOString() : null,
        },
      },
    });
  }, [
    selectedCategory,
    sortBy,
    startDate,
    isPaidOrFree,
    searchQuery,
    getFilteredEvents,
  ]);

  if (categoryData.loading) {
    return (
      <Center mt={"20%"}>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='purple.500'
          size='xl'
        />
      </Center>
    );
  }

  const handleDateButtonClick = (newTimeFrame: string) => {
    const actualDate = handleTimeFrameFilter(newTimeFrame, today);
    setStartDate(actualDate);
    if (timeFrame === newTimeFrame) {
      setTimeFrame("");
      setStartDate(null);
    } else {
      setTimeFrame(newTimeFrame);
      setStartDate(actualDate);
    }
  };

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setLocalSearchQuery(nextValue); // update value in state immediately
    debouncedSave(nextValue); // update value in the state after debounce for Apollo query
  };

  return (
    <ChakraProvider>
      <Container maxW='container.xl' py={12}>
        <Box textAlign='center'>
        <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={6}>
            <InputGroup flex='3'>
              <InputLeftElement pointerEvents='none'>
                <SearchIcon color='gray.400' />
              </InputLeftElement>
              <Input
                type='text'
                placeholder='Search events...'
                value={localSearchQuery}
                onChange={handleSearchChange}
                size='lg'
              />
            </InputGroup>

            {/* Category dropdown */}
            <Select
              flex='1'
              size='lg'
              placeholder='All Categories'
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
            >
              {categoryData.data.categories.map((cat) => (
                <option value={cat._id} key={cat._id}>
                  {cat.name}
                </option>
              ))}
            </Select>
          </Stack>

          {/* Date selection buttons */}

          <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={6}>
            <Button
              onClick={() => handleDateButtonClick("Today")}
              variant={timeFrame === "Today" ? "solid" : "outline"}
              colorScheme='purple'
            >
              Today
            </Button>
            <Button
              onClick={() => handleDateButtonClick("Tomorrow")}
              variant={timeFrame === "Tomorrow" ? "solid" : "outline"}
              colorScheme='purple'
            >
              Tomorrow
            </Button>
            <Button
              onClick={() => handleDateButtonClick("This Weekend")}
              variant={timeFrame === "This Weekend" ? "solid" : "outline"}
              colorScheme='purple'
            >
              This Weekend
            </Button>
            <Button
              onClick={() => handleDateButtonClick("Next Weekend")}
              variant={timeFrame === "Next Weekend" ? "solid" : "outline"}
              colorScheme='purple'
            >
              Next Weekend
            </Button>
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={(date: Date | null) => {
                setStartDate(date);
                setTimeFrame("");
              }}
              isClearable
              placeholderText='Select a date'
              customInput={
                <Button variant='outline' colorScheme='purple' width='200px'>
                  {startDate ? startDate.toDateString() : "Select a date"}
                </Button>
              }
              popperClassName='date-picker-popper'
            ></DatePicker>

            {/* Free/Paid dropdown */}
            <Select
              flex='1'
              size='lg'
              placeholder='Free and Paid Events'
              value={isPaidOrFree}
              onChange={(e) => setIsPaidOrFree(e.target.value)}
            >
              <option value='free'>Free Events Only</option>
              <option value='paid'>Paid Events Only</option>
            </Select>

            {/* Sorting dropdown */}
            <Select
              flex='1'
              size='lg'
              placeholder='Sort By'
              value={sortBy}
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
            >
              <option value='date'>By Date</option>
              <option value='distance'>By Distance</option>
              <option value='popularity'>By Popularity</option>
            </Select>
          </Stack>

          {/* Results event cards */}
          {loading ? (
            <Center mt={200}>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='purple.500'
                size='xl'
              />
            </Center>
          ) : data && data.getFilteredEvents ? (
            <EventResults events={data.getFilteredEvents} />
          ) : (
            <NoEventsCard />
          )}
        </Box>
      </Container>
    </ChakraProvider>
  );
};

const EventResults: React.FC<{ events: Event[] }> = ({ events }) => (
  <Box
    display='grid'
    gridTemplateColumns={{
      base: "1fr",
      md: "repeat(2, 1fr)",
      lg: "repeat(3, 1fr)",
    }}
    gap={4}
    width='100%'
  >
    {events.length > 0 ? (
      events.map((event) => (
        <RouterLink to={"/event/" + event._id} key={event._id}>
          <EventCards {...event} />
        </RouterLink>
      ))
    ) : (
      <NoEventsCard />
    )}
  </Box>
);


const NoEventsCard: React.FC = () => (
  <Box
    mt={200}
    display='flex'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    textAlign='center'
    width='100%' 
  >
    <Text fontSize='2xl' fontWeight='bold' color='purple.600'>
      No Events Found
    </Text>
    <Text color='gray.600'>Check back later for upcoming events!</Text>
  </Box>
);

export default Explore;
