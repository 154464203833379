import {
  Badge,
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { FaGlobeAmericas, FaLock } from "react-icons/fa";
import { Event } from "../utilities/type-defs";

export default function EventCards(event: Event) {
  const formattedDate = format(
    new Date(`${event.date} ${event.time}`),
    "MMMM dd, yyyy, h:mm a"
  );
  // console.log(event.title,event.groupSize)


  return (
    <Center py={6}>
      <Box
        maxW={"445px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"lg"}
        rounded={"lg"}
        p={6}
        pos={"relative"}
      >
        <Badge
          colorScheme={"purple"}
          borderRadius={"full"}
          px={2}
          fontSize={"0.8em"}
          pos={"absolute"}
          top={8}
          right={8}
          zIndex={1}
        >
          {event.category.name}
        </Badge>
        <Image
          borderRadius={"lg"}
          src={
            event.imageUrl ||
            `https://spotme.com/wp-content/uploads/2020/07/Hero-1.jpg`
          }
          alt={event.title}
          mb={4}
          h={"200px"}
          w={"full"}
          objectFit={"cover"}
        />
        <Heading
          fontSize={"xl"}
          fontFamily={"Calibri, sans-serif"}
          fontWeight={500}
          mb={2}
          color={useColorModeValue("gray.700", "white")}
          textAlign={"left"}
        >
          {event.title}
        </Heading>
        <Flex justify={"space-between"} align={"center"} mb={2}>
          <Text color={"gray.500"} fontSize={"md"}>
            {formattedDate}
          </Text>
          <Flex align={"center"}>
            <Icon
              as={event.eventType === "Public" ? FaGlobeAmericas : FaLock}
              mr={1}
            />
            <Text color={"gray.500"} fontSize={"md"}>
              {event.eventType}
            </Text>
          </Flex>
        </Flex>
        <Flex justify={"space-between"} align={"center"} mb={4}>
          <Badge
            colorScheme={event.limitGroupSize === "Limit" ? "red" : "green"}
          >
            {event.limitGroupSize === "Limit" && event.groupSize !== null
              ? `Up to ${event.groupSize} participants`
              : "Unlimited participants"}
          </Badge>
          <Tooltip
            label={`Skill Level: ${event.skillLevel}`}
            aria-label='Skill Level'
          >
            <Badge colorScheme={"blue"}>{event.skillLevel}</Badge>
          </Tooltip>
        </Flex>
        {/* <Text color={"gray.500"} fontSize={"md"} textAlign={"left"}>
          Hosted by {hostName}
        </Text> */}
      </Box>
    </Center>
  );
}
