import { Box, Text, Flex, Icon } from "@chakra-ui/react";
import { FaUsers, FaUserTie, FaBuilding, FaRulerHorizontal, FaSkating, FaTicketAlt } from 'react-icons/fa';

const SpecificsSection = (event: any) => {
  const { eventType, groupSize, skillLevel, limitGroupSize, venueType, host } =
    event["event"];
  return (
    <Box>
      <Text fontWeight={"bold"} mb={2}> Specifics </Text>
      <Flex align="center" mb={1}>
        <Icon as={FaTicketAlt} color="gray.500" mr={2} />
        <Text>Event Type: {eventType}</Text>
      </Flex>
      <Flex align="center" mb={1}>
        <Icon as={FaUsers} color="gray.500" mr={2} />
        <Text>
          Event Size:{" "}
          {parseInt(groupSize) === -1 || groupSize === ""
            ? "No limit"
            : groupSize}
        </Text>
      </Flex>
      <Flex align="center" mb={1}>
        <Icon as={FaSkating} color="gray.500" mr={2} />
        <Text>Skill Level: {skillLevel}</Text>
      </Flex>
      {limitGroupSize !== "Limit" && (
        <Flex align="center" mb={1}>
          <Icon as={FaRulerHorizontal} color="gray.500" mr={2} />
          <Text>Group Size: {groupSize}</Text>
        </Flex>
      )}
      <Flex align="center" mb={1}>
        <Icon as={FaBuilding} color="gray.500" mr={2} />
        <Text>Venue Type: {venueType}</Text>
      </Flex>
      <Flex align="center" mb={1}>
        <Icon as={FaUserTie} color="gray.500" mr={2} />
        <Text>Hosted By: {host.fname}</Text>
      </Flex>
    </Box>
  );
};

export default SpecificsSection;
