import { StarIcon } from "@chakra-ui/icons";
import { Button, Icon, useColorModeValue } from "@chakra-ui/react";
import { FaTicketAlt } from "react-icons/fa";

const Favourited = ({ event }: any) => {
  const bg = useColorModeValue("red.600", "red.300");
  const color = useColorModeValue("white", "gray.900");
  const hoverBg = useColorModeValue("red.700", "red.400");
  return event.isFavorited || false ? (
    <Button
      aria-label='Unfavourite'
      size='lg'
      leftIcon={<StarIcon />}
      bg={bg}
      color={color}
      _hover={{
        bg: hoverBg,
        transform: "scale(1.05)",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
      boxShadow='xl'
      onClick={() => {
        /* logic to unfavourite */
      }}
    >
      Unfavourite
    </Button>
  ) : (
    <Button
      aria-label='Favourite'
      size='lg'
      variant={"outline"}
      leftIcon={<StarIcon />}
      bg={bg}
      color={color}
      _hover={{
        bg: hoverBg,
        transform: "scale(1.05)",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
      boxShadow='xl'
      onClick={() => {
        /* logic to favourite */
      }}
    >
      Favourite
    </Button>
  );
};

const BuyTicketButton = () => {
  const bg = useColorModeValue("blue.600", "blue.300");
  const color = useColorModeValue("white", "gray.900");
  const hoverBg = useColorModeValue("blue.700", "blue.400");

  return (
    <Button
      size='lg'
      bg={bg}
      color={color}
      _hover={{
        bg: hoverBg,
        transform: "scale(1.05)",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
      leftIcon={<Icon as={FaTicketAlt} w={6} h={6} />}
      boxShadow='xl'
    >
      Buy Ticket
    </Button>
  );
};

export { BuyTicketButton, Favourited };
