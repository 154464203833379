import { useQuery } from "@apollo/client";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Heading,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { GET_USER, GET_USER_ID_FROM_LOGIN } from "../../utilities/users-gql";

const userId = GET_USER_ID_FROM_LOGIN();
const AccountManagement: React.FC = () => {
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { userId: userId },
  });

  const navigate = useNavigate();
  const user = data?.userById;
  const theme = useTheme();
  const bgColor = useColorModeValue("white", "gray.900");

  if (loading) {
    return (
      <Center mt={"20%"}>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='purple.500'
          size='xl'
        />
      </Center>
    );
  }

  if (error) {
    navigate("/explore");
    return null;
  }

  return (
    <Container maxW='container.xl' py={12}>
      <Box pl='10%' pr='10%' mb={10}>
        <Heading fontSize='xl' textAlign='center'>
          View Profile
        </Heading>
      </Box>
      <Center py={6}>
        <Box
          maxW={"400px"}
          w={"full"}
          bg={bgColor}
          boxShadow={"2xl"}
          rounded={"lg"}
          p={6}
          border='1px'
          borderColor={theme.colors.gray[200]}
          textAlign={"center"}
        >
          <Avatar size={"xl"} mb={4} name={user.fname} pos={"relative"} />
          <Heading fontSize={"2xl"} fontFamily={"body"}>
            {user.fname} {user.sname}
          </Heading>
          <Text fontWeight={600} color={"gray.500"} mb={1}>
            {user.email}
          </Text>

          <Stack
            align={"center"}
            justify={"center"}
            direction={"row"}
            mt={1}
            mb={4}
          >
            <Badge px={2} py={1} bg={bgColor} fontWeight={"400"}>
              Mobile: {user.mobile}
            </Badge>
          </Stack>
          <Text textAlign={"center"} color={bgColor} px={3}>
            {user.desc}
          </Text>

          <RouterLink to='/edit-profile' state={{ user: user }}>
            <Button
              w={"full"}
              colorScheme='green'
              fontSize={"sm"}
            >
              Edit{" "}
            </Button>
          </RouterLink>
        </Box>
      </Center>
    </Container>
  );
};

export default AccountManagement;
