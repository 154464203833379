import { useMutation } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FORGOT_PASSWORD } from "../../../utilities/users-gql";

function ForgotPasswordForm({ login, register }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast();

  const [forgotPasswordMutation, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      setIsSuccess(true);
      toast({
        title: "Email Sent.",
        description: "Check your email for the reset link.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const handleResetRequest = async () => {
    setError(null);
    await forgotPasswordMutation({ variables: { email } });
  };

  return (
    <>
      <Button onClick={onOpen} background={"none"} padding={0} cursor={"pointer"} height={"fit-content"} color={"blue.500"}>Forgot Password?</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx='auto' my='auto'>
          <ModalHeader>Forgot Password</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pb={6}>
            {isSuccess ? (
              <Alert status='success'>
                <AlertIcon />
                An email has been sent with instructions to reset your password.
              </Alert>
            ) : (
              <FormControl isInvalid={!!error}>
                <Input
                  placeholder='Enter your email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isDisabled={loading}
                />
                <FormErrorMessage>{error}</FormErrorMessage>
                <Button
                  mt={4}
                  colorScheme='blue'
                  isLoading={loading}
                  onClick={handleResetRequest}
                  isDisabled={!email || loading}
                >
                  {loading ? <Spinner /> : "Send Reset Email"}
                </Button>
              </FormControl>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ForgotPasswordForm;
