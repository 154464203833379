import { useQuery } from "@apollo/client";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import EventCards from "../../components/EventCards";
import { GET_EVENTS } from "../../utilities/events-gql";
import { Event } from "../../utilities/type-defs";


const Subscriptions: React.FC = () => {
  const { loading, error, data } = useQuery(GET_EVENTS);
  const [searchQuery, setSearchQuery] = useState("");
  const [timeFrame, setTimeFrame] = useState("Upcoming");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleTimeFrame = (frame: string) => {
    console.log(frame);
    setTimeFrame(frame);
  };

  if (loading) {
    return (
      <Center mt={"20%"}>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='purple.500'
          size='xl'
        />
      </Center>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const filteredEvents = filterEvents(data, searchQuery, timeFrame, selectedTags);

  const gridSize = 3;

  const mainEventsGrid: Event[][] = [];
  for (let i = 0; i < filteredEvents.length; i += gridSize) {
    mainEventsGrid.push(filteredEvents.slice(i, i + gridSize));
  }

  const newColumnEventsGrid: Event[][] = [];
  for (let i = 0; i < filteredEvents.length; i += gridSize) {
    newColumnEventsGrid.push(filteredEvents.slice(i, i + gridSize));
  }
  return (
    <Container maxW='container.xl' py={12}>
      <Box pl='10%' pr='10%' mb={10}>
        <Heading fontSize='xl' textAlign='center'>
          Subscriptions
        </Heading>
        <Tabs variant='soft-rounded' colorScheme='purple' mt={6}>
          <Center>
            <VStack>
              <TabList>
                <Flex mb={3}>
                  {["Upcoming", "Past", "Favourited"].map((frame) => (
                    <Tab
                      margin={2}
                      key={frame}
                      onClick={() => handleTimeFrame(frame)}
                    >
                      {frame}
                    </Tab>
                  ))}
                </Flex>
              </TabList>
              <InputGroup maxW={"330px"} mt={1}>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='gray.400' />
                </InputLeftElement>
                <Input
                  type='text'
                  placeholder='Search your events...'
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </InputGroup>
            </VStack>
          </Center>
          <TabPanels>
            <TabPanel>
              <Box
                mt={3}
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <Box
                  display='grid'
                  gridTemplateColumns={`repeat(${gridSize}, 1fr)`}
                  gap={4}
                >
                  {mainEventsGrid.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      {row.map((event) => (
                        <RouterLink
                          to='/components/EventPage'
                          state={{ event: event }}
                        >
                          <EventCards {...event} />
                        </RouterLink>
                      ))}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                mt={3}
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <Box
                  display='grid'
                  gridTemplateColumns={`repeat(${gridSize}, 1fr)`}
                  gap={4}
                >
                  {mainEventsGrid.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      {row.map((event) => (
                        <RouterLink
                          to='/components/EventPage'
                          state={{ event: event }}
                        >
                          <EventCards {...event} />
                        </RouterLink>
                      ))}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                mt={3}
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <Box
                  display='grid'
                  gridTemplateColumns={`repeat(${gridSize}, 1fr)`}
                  gap={4}
                >
                  {mainEventsGrid.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      {row.map((event) => (
                        <RouterLink to={"/Event/" + event._id} key={event._id}>
                          <EventCards {...event} />
                        </RouterLink>
                      ))}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};

const filterEvents = (data, searchQuery, timeFrame, selectedTags) => {
  const todayDate = new Date();
  const formattedDate =
    todayDate.getFullYear() +
    "-" +
    (todayDate.getMonth() + 1) +
    "-" +
    todayDate.getDate;
  const filteredEvents = data.events.filter(
    (event) =>
      event.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      ((timeFrame === "Upcoming" && event.date > formattedDate) ||
        (timeFrame === "Past" && event.date < formattedDate) ||
        (timeFrame === "Favourited" && event.favourited === true)) &&
      (selectedTags.length === 0 ||
        selectedTags.some((tag) => event.tags.includes(tag.toLowerCase())))
  );
  return filteredEvents;
};

export default Subscriptions;
