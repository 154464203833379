import { gql } from "@apollo/client";

const GET_EVENT_BY_USER =
gql`
    query GetEventByUser {
        getHostingEventById(userId: "64f302bd6189a5367f12aee0") {
            _id
            title
            description
            date
            imageUrl
            category {
                name
            }
            placeAddress
        }
    }
`

const GET_EVENT_BY_ID =
    gql`
    query GetEventById ($eventId: String!){
      eventById(eventId: $eventId) {
        _id
        title 
        description
        date
        time
        venueType
        imageUrl
        place
        placeId
        placeAddress
        placeLat
        placeLng
        eventType
        limitGroupSize
        groupSize
        tags
        skillLevel
        createdAt
        updatedAt
        deletedAt
        isFavorited
        category {
          _id
          name
          iconType
          iconName
          numberOfEvents
        }
        host {
          _id
          fname
          sname
          email
          mobile
          profileImg
        }
        ticketTypes {
          _id
          name
          price
          currency
          startDate
          endDate
          limit
          description
          status
        }
      }
    }`;

const GET_EVENTS =
gql`
    query GetAllEvents{
        events{
            _id
            title
            description
            date
            time
            venueType
            imageUrl
            place
            placeId
            placeAddress
            placeLat
            placeLng
            eventType
            limitGroupSize
            groupSize
            tags
            skillLevel
            createdAt
            updatedAt
            deletedAt
            isFavorited
            category {
                _id
                name
                iconType
                iconName
                numberOfEvents
            }
            ticketTypes {
                _id
                name
                price
                currency
                startDate
                endDate
                limit
                description
                status
            }
        }
    }`;

gql`
  input EventFilterInput {
    category: String
    sortBy: String
    isPaidOrFree: String
    searchQuery: String
    startDate: String
  }`

const GET_EVENTS_FILTERED = gql`
  query GetFilteredEvents($filter: EventFilterInput) {
    getFilteredEvents(filter: $filter) {
      _id
      title
      description
      date
      time
      venueType
      imageUrl
      place
      placeId
      placeAddress
      placeLat
      placeLng
      eventType
      limitGroupSize
      groupSize
      tags
      skillLevel
      createdAt
      updatedAt
      deletedAt
      isFavorited
      category {
        _id
        name
        iconType
        iconName
        numberOfEvents
      }
      ticketTypes {
        _id
        name
        price
        currency
        startDate
        endDate
        limit
        description
        status
      }
    }
  }
`;

const GET_CATEGORIES = gql`
    query GetCategories {
      categories {
        _id
        name
        numberOfEvents
      }
    }`;


export { GET_CATEGORIES, GET_EVENTS, GET_EVENT_BY_ID, GET_EVENT_BY_USER,GET_EVENTS_FILTERED };




