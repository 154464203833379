import { useMutation } from "@apollo/client";
import { ViewIcon, ViewOffIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MobileAppLinks from "../../../components/MobileAppLinks";
import { loginSessionData } from "../../../utilities/type-defs";
import { SIGN_IN } from "../../../utilities/users-gql";
import { handleEmailValidation } from "../../../utilities/utils";
import ForgotPasswordForm from "../ForgotPassword/ForgotPasswordForm";
import RegisterForm from "../Register/RegisterForm";

function LoginForm({ login, register, forgotPassword }) {
  /* Mutation Queries */
  const [LoginMutationQueryCallback] = useMutation(SIGN_IN);
  /* Page State Information */
  const [isHandleSignInError, setIsHandleSignInError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  type FormData = {
    email: string;
    password: string;
    rememberMe: string;
  };

  /*Get the logic from the session handler for the 'remember me' function to input the field data here... */
  // @ts-ignore
  const rememberMe: string = !!sessionStorage.getItem("rememberMe")
    ? sessionStorage.getItem("rememberMe")
    : "";

  /* Form Controllers */
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: rememberMe,
      password: "",
      rememberMe: "",
    },
  });

  /* Login Handlers */

  const handleSignIn = async (data: FormData) => {
    if (data.rememberMe) sessionStorage.setItem("rememberMe", data.email);
    else sessionStorage.removeItem("rememberMe");

    //just to tell the page if it's still loading information or data.
    setIsLoading(true);

    LoginMutationQueryCallback({
      variables: { email: data.email, password: data.password },
      update(cache, result) {
        setIsLoading(false);
        //let's check if this is a merchant or not...
        if (result.data) {
          if (result.data.login.userType) {
            const epochTime = Math.floor(new Date().getTime() / 1000);

            /* Dig out and sort the login data. */
            let loginData: loginSessionData = {
              userId: result.data.login.userId,
              userType: result.data.login.userType,
              token: result.data.login.token,
              tokenExpiration: result.data.login.tokenExpiration,
              sessionExpire:
                epochTime + (result.data.login.tokenExpiration * 3600 - 120),
            };

            sessionStorage.setItem("loginDetails", JSON.stringify(loginData));
            setIsHandleSignInError(false);
            // login.close()
            window.location.reload();
          } else setIsHandleSignInError(true);
        } else setIsHandleSignInError(true);
      },
    }).catch((e) => {
      console.log(e);
      setIsHandleSignInError(true);
      setIsLoading(false);
    });
  };
  return (
    <Modal isOpen={login.isOpen} onClose={login.close}>
      <ModalOverlay />
      <ModalContent mx='auto' my='auto'>
        <ModalHeader>Login</ModalHeader>
        <ModalCloseButton onClick={login.close} />
        <ModalBody pb={6}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={2} px={2}>
            <Box>
              <Stack spacing={4}>
                {isHandleSignInError ? <SignInError /> : ""}
                <Controller
                  control={control}
                  name={"email"}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl isInvalid={!!errors.email}>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        id={"email"}
                        disabled={isLoading}
                        onChange={onChange}
                        type='email'
                        onBlur={onBlur}
                        value={value}
                        placeholder={"someone@example.com"}
                      />
                      {!!errors.email ? (
                        <FormErrorMessage>
                          A valid email is required
                        </FormErrorMessage>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: true,
                    validate: handleEmailValidation,
                  }}
                />

                <Controller
                  control={control}
                  name={"password"}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl isInvalid={!!errors.password} isRequired>
                      <FormLabel>Password:</FormLabel>
                      <InputGroup>
                        <Input
                          id={"password"}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder={"●●●●●●●●●●●●"}
                          type={showPassword ? "text" : "password"}
                        />
                        <InputRightElement width='3rem'>
                          <Button
                            h='1.5rem'
                            size='sm'
                            onClick={handlePasswordVisibility}
                          >
                            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {!!errors.password ? (
                        <FormErrorMessage>
                          A valid password is required
                        </FormErrorMessage>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: true,
                    minLength: 4,
                  }}
                />

                <Stack spacing={5}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  >
                    <Controller
                      control={control}
                      name={"rememberMe"}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                          value={value}
                          disabled={isLoading}
                          onChange={onChange}
                          onBlur={onBlur}
                        >
                          Remember me
                        </Checkbox>
                      )}
                    />
                    <Flex justify='flex-end'>
                      <ForgotPasswordForm
                        login={login}
                        register={register}
                      />
                    </Flex>
                  </Stack>

                  <Button
                    onClick={handleSubmit(handleSignIn)}
                    disabled={isLoading}
                    colorScheme='blue'
                  >
                    {isLoading ? <Spinner /> : "Continue"}
                  </Button>

                  <Flex justify='center'>
                    <Text
                      cursor={"pointer"}
                      color={"gray.400"}
                      onClick={register.open}
                    >
                      Don't have an account?
                    </Text>
                  </Flex>

                  <RegisterForm
                    login={login}
                    register={register}
                    forgotPassword={forgotPassword}
                  />
                  <Button
                    variant='outline'
                    onClick={register.open}
                    colorScheme='blue'
                  >
                    Register
                  </Button>
                </Stack>
              </Stack>
            </Box>
            <MobileAppLinks />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
const SignInError = () => (
  <Box rounded={"md"} background={"tomato"} p={2}>
    <Center>
      <Heading size='sm'>
        <Center>
          <WarningIcon boxSize={5} mt={1} mr={2}></WarningIcon> Incorrect
          credentials
        </Center>
      </Heading>
    </Center>
  </Box>
);

export default LoginForm;
