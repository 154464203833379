import { useQuery } from "@apollo/client";
import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { GET_EVENT_BY_ID } from "../../utilities/events-gql";
import { Event } from "../../utilities/type-defs";
import useReadMore from "../../utilities/useReadMore";
import { formattedDate } from "../../utilities/utils";
import { BuyTicketButton, Favourited } from "./ActionButtons";
import DescriptionSection from "./DescriptionSection";
import { LocationSection } from "./LocationSection";
import RefundSection from "./RefundSection";
import SocialSharing from "./SocialSharing";
import SpecificsSection from "./SpecificsSection";
import { TagsSection } from "./TagsSection";

export default function EventPage(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_EVENT_BY_ID, {
    variables: { eventId: id },
  });

  if (!id) {
    navigate("/explore");
    return <></>;
  }

  if (loading)
    return (
      <Center mt={"20%"}>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='purple.500'
          size='xl'
        />
      </Center>
    );
  if (error) return <div>Error: {error.message}</div>;

  const event: Event = data.eventById;
  return <EventDetails event={event} />;
}

function EventDetails({ event }: { event: Event }) {
  const url = window.location.href;
  const eventDate = formattedDate(event);

  const descriptionContentPromise = Promise.resolve(event.description || "");
  const refundPolicyContentPromise = Promise.resolve(
    `All ticket sales for the event are final. Refunds will only be provided if the event is canceled by the organizers and not rescheduled.`
  );
  const descriptionReadMore = useReadMore(descriptionContentPromise);
  const refundReadMore = useReadMore(refundPolicyContentPromise);

  const windowWidth = window.innerWidth * 0.6;
  const windowHeight = window.innerHeight * 0.6;

  return (
    <Container maxW='container.xl' py={12}>
      <Box>
        <Stack spacing={5}>
          <Breadcrumbs
            items={[
              {
                text: "Explore",
                to: "/",
              },
              {
                text: event.title,
                isCurrentPage: true,
              },
            ]}
          />

          <HStack w='100%' justifyContent='space-between'>
            <Stack spacing={2} alignItems='flex-start'>
              <Heading size='xl'>{event.title}</Heading>
              <Text>{eventDate}</Text>
            </Stack>

            <Stack spacing={2} alignItems='flex-end'>
              <SocialSharing
                url={url}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
              />
            </Stack>
          </HStack>

          <Box>
            {event.imageUrl ? (
              <Image
                src={event.imageUrl}
                alt={event.title}
                borderRadius='lg'
                objectFit='cover'
                h='350px'
                w='full'
              />
            ) : (
              <Center h='300px' bg='gray.200'>
                <Text fontSize='xl'>This event has no image</Text>
              </Center>
            )}
          </Box>

          <HStack spacing={4} justifyContent='flex-end'>
            <Favourited event={event} />
            <RouterLink to='/purchase' state={{ event: event }}>
              <BuyTicketButton />
              {/* {event.deletedAt != null && <BuyTicketButton />} */}
            </RouterLink>
          </HStack>

          <DescriptionSection
            descriptionReadMore={descriptionReadMore}
            eventDescription={event.description || ""}
          />

          <Flex w='100%' alignItems='flex-start'>
            <VStack w='50%' alignItems='flex-start'>
              <SpecificsSection event={event} />
              <TagsSection tags={event.tags} />
            </VStack>
            <Box w='50%' alignItems='flex-start'>
              <LocationSection
                place={event.place}
                address={event.placeAddress}
                lat={event.placeLat}
                lng={event.placeLng}
              />
            </Box>
          </Flex>

          <RefundSection refundReadMore={refundReadMore} />
        </Stack>
      </Box>
    </Container>
  );
}
