import { Box, Center, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { TicketType } from "../../../utilities/type-defs";

export default function PricingItem(ticketType: TicketType) {
  return (
    <Center py={6} w='full'>
      <Box
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"md"}
        overflow={"hidden"}
        p={6}
      >
        <Stack
          textAlign={"center"}
          spacing={4}
          color={useColorModeValue("gray.800", "white")}
          align={"center"}
        >
          <Text
            fontSize={"lg"}
            fontWeight={600}
            bg={useColorModeValue("green.50", "green.900")}
            p={2}
            px={3}
            color={"green.500"}
            rounded={"full"}
          >
            {ticketType.name}
          </Text>
          <Stack direction={"row"} align={"center"} justify={"center"}>
            <Text fontSize={"2xl"}>$</Text>
            <Text fontSize={"4xl"} fontWeight={800}>
              {ticketType.price}
            </Text>
          </Stack>
          <Text
            fontSize={"md"}
            textAlign={"center"}
            color={useColorModeValue("gray.600", "gray.400")}
          >
            {ticketType.description}
          </Text>
        </Stack>
      </Box>
    </Center>
  );
}
