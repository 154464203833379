import { useEffect, useRef, useState } from 'react';

interface ReadMoreHook {
  isOpen: boolean;
  toggleReadMore: () => void;
  showReadMoreButton: boolean;
  contentRef: React.RefObject<HTMLDivElement | null>;
  content: string; // content property for the text content
  isContentLoaded: boolean; 
}

// Inside your useReadMore hook
function useReadMore(initialContentPromise: Promise<string>): ReadMoreHook {
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [content, setContent] = useState("");
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    initialContentPromise.then((initialContent) => {
      if (contentRef.current) {
        setShowReadMoreButton(contentRef.current.scrollHeight !== contentRef.current.clientHeight);
        setContent(initialContent);
        setIsContentLoaded(true);  // Set the data as loaded
      }
    });
  }, [initialContentPromise]);


  const toggleReadMore = () => {
    console.log('Toggling Read More'); // Log when function is called
    setIsReadMoreOpen(prev => !prev); // Toggle based on previous value
  };

  return { 
    isOpen: isReadMoreOpen, 
    toggleReadMore, 
    showReadMoreButton, 
    contentRef, 
    content,
    isContentLoaded  
};
}

export default useReadMore;