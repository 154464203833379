import { Center, Stack } from "@chakra-ui/react";

const MobileAppLinks = () => (
  <Stack align={"center"} w='100%'>
    <Center>
      <Stack m={-1} ml={-2}>
        <a
          rel='noreferrer'
          target={"_blank"}
          href='https://play.google.com/store/apps/details?id=com.synergize.synergy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
        >
          <img
            className={"google-image"}
            alt='Get it on Google Play'
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
          />
        </a>
      </Stack>
      {" "}
      <Stack>
        <a
          rel='noreferrer'
          target={"_blank"}
          href='https://apps.apple.com/au/app/synergy/id6444486910'
        >
          <img
            className={"apple-image"}
            alt='Get it on the App store'
            src='/images/apple.svg'
          />
        </a>
      </Stack>
    </Center>
  </Stack>
);

export default MobileAppLinks;
