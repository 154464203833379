import {
  Badge,
  Box,
  Flex,
  Icon,
  Image,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { FaClock, FaMapMarkerAlt, FaTags, FaUser } from "react-icons/fa";
import { Event } from "../../../utilities/type-defs";

export default function EventDetailsCard({ event }: { event: Event }) {
  const formattedDate = format(
    new Date(`${event.date} ${event.time}`),
    "MMMM dd, yyyy, h:mm a"
  );

  return (
    <Box
      maxW='md'
      borderWidth='1px'
      borderRadius='lg'
      height="fit-content"
      boxShadow='lg'
      bg={useColorModeValue("white", "gray.800")}
      m={3}
    >
      <Image
        src={event.imageUrl}
        alt={event.title}
        h='200px'
        w='full'
        objectFit='cover'
      />

      <Box p='6'>
        <Badge borderRadius='full' px='2' colorScheme='teal'>
          {event.category.name}
        </Badge>

        <Text mt='2' fontWeight='semibold' as='h4' fontSize='xl'>
          {event.title}
        </Text>

        <Text color='gray.500' mt='2'>
          <Icon as={FaClock} /> {formattedDate}
        </Text>

        <Text color='gray.500' mt='2'>
          <Icon as={FaMapMarkerAlt} /> {event.placeAddress}
        </Text>

        <Text color='gray.500' mt='2'>
          <Icon as={FaUser} /> Hosted by Merchant Name
        </Text>

        <Flex align='center' mt='2'>
          <Icon as={FaTags} color='gray.500' />
          <Stack direction='row' spacing={1} ml='1'>
            {event.tags.map((tag, index) => (
              <Tooltip key={index} label={tag} aria-label={tag}>
                <Badge colorScheme='purple'>{tag}</Badge>
              </Tooltip>
            ))}
          </Stack>
        </Flex>

        <Flex align='center' mt='2' justify='space-between'>
          <Text color='gray.500'>
            {event.eventType === "Public" ? "Public Event" : "Private Event"}
          </Text>

          <Badge
            colorScheme={event.limitGroupSize === "Limit" ? "red" : "green"}
          >
            {event.limitGroupSize === "Limit"
              ? `Up to ${event.groupSize} participants`
              : "Unlimited participants"}
          </Badge>
        </Flex>
      </Box>
    </Box>
  );
}
