import React, { useState } from 'react';

import { Box, Button, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text, Image, MenuDivider, MenuGroup, Heading, VStack, Accordion, AccordionButton, AccordionItem, AccordionPanel, Container, Flex, useColorModeValue, FormControl, FormLabel, Switch, List, ListItem, Divider } from '@chakra-ui/react';
import { BellIcon, ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons'
import {FaTwitter, FaFacebook,} from 'react-icons/fa'
import { FlowFlags, getEffectiveConstraintOfTypeParameter } from 'typescript';
import {Link as RouterLink} from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

interface Event {
    _id: number;
    title: string;
    date: string;
    placeLat : Number;
    placeLng: Number;
    imageUrl: string;
    tags: string[];
    isFavorited: boolean;
    description: string;
    address: string;
    ticketTypes: TicketType[];
  }
  interface TicketType {
    _id: number,
    name: string,
    price: string,
    currency: string,
    startDate: string,
    endDate: string,
    description: string,
    status: string
  }
  
  const GET_EVENTS = gql`
      query GetEvents {
        events {
          _id
          title
          date
          placeLat
          placeLng
          imageUrl
          tags
          isFavorited
          description
          placeAddress
          ticketTypes {
            _id
            name
            price
            currency
            startDate
            endDate
            description
            status
          }
        }
      }
    `
  
  
  const FullNotifications: React.FC = () => {
    const { loading, error, data } = useQuery(GET_EVENTS);
    const gridSize = 1;
    if (loading) return <></>;
    const mainEventsGrid: Event[][] = [];
    for (let i = 0; i < data.events.length; i += gridSize) {
      mainEventsGrid.push(data.events.slice(i, i + gridSize));
    }
  
    
    return (
        <div>
            <Flex  pl='30%' pr='10%' direction="column">
                    <Box p={5}>
                        <Heading fontSize="xl">Notification Center</Heading>
                    </Box>
                    <HStack>
                    <Box>
                            <div>
                                <Text fontSize="lg" fontWeight={"bold"}>Change Settings:</Text>
                                <Text>Set your preferred notification settings</Text>
                            </div>
                            <div >
                                <Flex direction="row" padding={2}>
                                    <Switch id='all-notifications' marginRight={3} marginTop={0.5}/> 
                                    <Text>All Notifications</Text>
                                </Flex>
                                <Flex direction="row" padding={2}>
                                    <Switch id='admin-notifications' marginRight={3} marginTop={0.5}/> 
                                    <Text>Admin Notifications</Text>
                                </Flex>
                                <Flex direction="row" padding={2}>
                                    <Switch id='joinLeave-notifications' marginRight={3} marginTop={0.5}/> 
                                    <Text>Event Subscriptions</Text>
                                </Flex>
                                <Flex direction="row" padding={2}>
                                    <Switch id='chat-notifications' marginRight={3} marginTop={0.5}/> 
                                    <Text>Chats</Text>
                                </Flex>
                            </div>         
                        </Box>
                        <Box >
                            <VStack>
                                <Box>
                                    <List  maxH={'675px'}sx={{overflowY:"scroll"}}  >
                                    {mainEventsGrid.map((row, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                        {row.map(event => (
                                        <RouterLink to="/components/EventPage" state={{event : event}}>
                                            <ListItem >
                                                <Box  w={'445px'} h={'100px'}
                                                rounded={'md'}
                                                p={5}
                                                m={1}
                                                bgColor={'white'} 
                                                overflow={'hidden'}>
                                                    <HStack>
                                                        <Box>
                                                            <Image
                                                            fit={'cover'}
                                                            boxSize='3.5rem'
                                                            borderRadius='5px'
                                                            src={event.imageUrl}
                                                            alt={event.title}
                                                            mr='12px'/>
                                                        </Box>
                                                        <Box>
                                                            <Text>{event.title}</Text>
                                                            <Text>{event.date}</Text>
                                                        </Box>
                                                    </HStack>
                                                </Box>
                                                <Divider />
                                            </ListItem>
                                        </RouterLink>
                                        ))}
                                        </React.Fragment>
                                    ))}
                                    </List>
                                </Box>
                            </VStack>
                        </Box>
                        
                    </HStack>
            </Flex>
        </div>
        
    );
 
}
export default FullNotifications;