import { gql } from "@apollo/client";

const CREATE_TICKET =
gql`
    mutation CreateTicket ($ticketInput: TicketInput!){
        createTicket(
            ticketInput: $ticketInput
        ) {
            _id
        }
    }
`
export { CREATE_TICKET };




