import { useMutation } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import MobileAppLinks from "../../../components/MobileAppLinks";
import { CREATE_USER } from "../../../utilities/users-gql";
import { handleEmailValidation } from "../../../utilities/utils";
import LoginForm from "../Login/LoginForm";

function RegisterForm({ login, register, forgotPassword }) {
  /* Mutation Queries */
  const [createUserMutationQuery, createUserMutationData] =
    useMutation(CREATE_USER);

  /* Form Controllers */
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      fname: "",
      sname: "",
      email: "",
      password: "",
      confirm_password: "",
    },
  });

  let pwd = watch("password");

  type FormData = {
    fname: string;
    sname: string;
    email: string;
    password: string;
    type?: string;
    confirm_password?: string;
  };

  if (createUserMutationData.loading)
    return (
      <Flex align={"top"} justify={"center"}>
        <Heading fontSize={"4xl"}>Loading...</Heading>
      </Flex>
    );

  if (createUserMutationData.error)
    return (
      <Flex align={"top"} justify={"center"}>
        {createUserMutationData.error.message}
      </Flex>
    );

  if (createUserMutationData.data)
    return (
      <Flex align={"top"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Alert colorScheme={"teal"} rounded={10}>
            <Heading fontSize={"2xl"}>User Created Successfully!</Heading>
          </Alert>
        </Stack>
      </Flex>
    );

  const handleRegistration = async (data: FormData) => {
    //delete the unused confirm password.
    delete data.confirm_password;
    //push the type
    data.type = "general";
    //send the query.
    await createUserMutationQuery({ variables: { user: data } }).catch((e) => {
      console.log(e);
    });
  };

  return (
    <Modal isOpen={register.isOpen} onClose={register.close}>
      <ModalOverlay />
      <ModalContent mx='auto' my='auto'>
        <ModalHeader>Create an account</ModalHeader>
        <ModalCloseButton onClick={register.close} />
        <ModalBody pb={6}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={2} px={2}>
            <Box>
              <Stack spacing={4}>
                <Controller
                  control={control}
                  name={"fname"}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl isInvalid={!!errors.fname} isRequired>
                      <FormLabel>First Name:</FormLabel>
                      <Input
                        id={"fname"}
                        onChange={onChange}
                        type='text'
                        onBlur={onBlur}
                        value={value}
                        placeholder={"John"}
                      />
                      {!!errors.fname ? (
                        <FormErrorMessage>
                          A valid first name is required
                        </FormErrorMessage>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: true,
                  }}
                />

                <Controller
                  control={control}
                  name={"sname"}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl isInvalid={!!errors.sname} isRequired>
                      <FormLabel>Surname:</FormLabel>
                      <Input
                        id={"sname"}
                        onChange={onChange}
                        type='text'
                        onBlur={onBlur}
                        value={value}
                        placeholder={"Doe"}
                      />
                      {!!errors.sname ? (
                        <FormErrorMessage>
                          A valid surname is required
                        </FormErrorMessage>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: true,
                  }}
                />

                <Controller
                  control={control}
                  name={"email"}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl isInvalid={!!errors.email} isRequired>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        id={"email"}
                        onChange={onChange}
                        type='email'
                        onBlur={onBlur}
                        value={value}
                        placeholder={"someone@example.com"}
                      />
                      {!!errors.email ? (
                        <FormErrorMessage>
                          A valid email is required
                        </FormErrorMessage>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: true,
                    validate: handleEmailValidation,
                  }}
                />

                <Controller
                  control={control}
                  name={"password"}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl isInvalid={!!errors.password} isRequired>
                      <FormLabel>Password:</FormLabel>
                      <Input
                        id={"password"}
                        onChange={onChange}
                        type='password'
                        onBlur={onBlur}
                        value={value}
                        placeholder={"●●●●●●●●●●●●"}
                      />
                      {!!errors.password ? (
                        <FormErrorMessage>
                          A valid password is required
                        </FormErrorMessage>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: true,
                    minLength: 5,
                  }}
                />

                <Controller
                  control={control}
                  name={"confirm_password"}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl
                      isInvalid={!!errors.confirm_password}
                      isRequired
                    >
                      <FormLabel>Password:</FormLabel>
                      <Input
                        id={"password"}
                        onChange={onChange}
                        type='password'
                        onBlur={onBlur}
                        value={value}
                        placeholder={"●●●●●●●●●●●●"}
                      />
                      {!!errors.password ? (
                        <FormErrorMessage>
                          {"The passwords must match"}
                        </FormErrorMessage>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: true,
                    validate: (value) =>
                      value === pwd || "The passwords do not match",
                  }}
                />

                <Button
                  colorScheme='purple'
                  onClick={handleSubmit(handleRegistration)}
                >
                  {"Register"}
                </Button>

                <Flex justify='center'>
                  <Text
                    cursor={"pointer"}
                    color={"gray.400"}
                    onClick={login.open}
                  >
                    Already have an account?
                  </Text>
                </Flex>
                <LoginForm
                  login={login}
                  register={register}
                  forgotPassword={forgotPassword}
                />
                <Button
                  variant='outline'
                  onClick={login.open}
                  colorScheme='blue'
                >
                  Login
                </Button>
              </Stack>
            </Box>
            <MobileAppLinks />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default RegisterForm;
