import { addDays, endOfWeek } from 'date-fns';

const handleTimeFrameFilter = (frame: string, today: Date) => {
  switch (frame) {
    case "Today":
      return today;
    case "Tomorrow":
      return addDays(today, 1);
    case "This Weekend":
      const daysUntilThisSaturday = (6 - today.getDay() + 7) % 7;
      return addDays(today, daysUntilThisSaturday);
    case "Next Weekend":
      const daysUntilNextSaturday = (6 - today.getDay() + 7) % 7 + 7;
      return addDays(today, daysUntilNextSaturday);
    default:
      return null;
  }
};

const capitalize = (s:string) => s && s[0].toUpperCase() + s.slice(1);

const isValidEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const isValidPhone = (phone) => {
  // You can add phone number validation logic here
  // For example, you can use a regex to validate the phone format
  return /^(\+\d{1,3})?(\d{10})$/.test(phone);
};

const handleEmailValidation = (email: string) => {
  return isValidEmail(email);
};

const formattedDate = (event) => {
  const eventDate = new Date(event.date);
  const eventTime = event.time;
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
    eventDate
  );
  const [hours, minutes] = eventTime.split(":");
  const parsedHours = parseInt(hours, 10);
  const period = parsedHours >= 12 ? "PM" : "AM";
  const hours12 = parsedHours > 12 ? parsedHours - 12 : parsedHours;
  const formattedTime = `${hours12}:${minutes} ${period}`;
  return `${formattedDate} - ${formattedTime}`;
};
const calculateTotalAmount = (peopleData) => {
  let total = 0.0;
  peopleData.forEach((personData) => {
    total += Number(personData.ticketType);
  });
  return total;
};

export {
  calculateTotalAmount,
  capitalize,
  formattedDate,
  handleEmailValidation,
  handleTimeFrameFilter,
  isValidEmail,
  isValidPhone,
};

