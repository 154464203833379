import React from 'react';
import AccountManagement from "./ViewProfile";

const Profile: React.FC = () => {
    return (
        <AccountManagement />
    );
}

export default Profile;
