import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import FullNotifications from "./components/FullNotifications";
import Header from "./components/Header";
import ResetPassword from "./pages/Auth/ForgotPassword/ResetPasswordPage";
import Logout from "./pages/Auth/Login/Logout";
import EventPage from "./pages/Events/EventPage";
import Explore from "./pages/Explore/Explore";
import Messages from "./pages/Messages/Messages";
import EditProfile from "./pages/Profile/EditProfile";
import Profile from "./pages/Profile/Profile";
import AccountManagement from "./pages/Profile/ViewProfile";
import Purchase from "./pages/Purchase/Purchase";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import type { loginSessionData } from "./utilities/type-defs";
function App() {
  /* Let's grab the current time*/
  const epochTime = Math.floor(new Date().getTime() / 1000);
  const [loginDetails, setLoginDetails] = useState<loginSessionData>({
    userId: "",
    sessionExpire: 200000,
    token: "",
    userType: "",
    tokenExpiration: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const resetLoginDetails = () => {
    let rememberMe = sessionStorage.getItem("rememberMe");
    let email = sessionStorage.getItem("email");

    setIsLoggedIn(false);
    setLoginDetails({
      userId: "",
      sessionExpire: 20000,
      token: "",
      userType: "",
      tokenExpiration: "",
    });
    sessionStorage.clear();

    if (rememberMe === "true") {
      sessionStorage.setItem("rememberMe", "true");
      sessionStorage.setItem("email", email || "");
    }
  };

  useEffect(() => {
    //console.log(sessionStorage.getItem("loginDetails"));
    if (sessionStorage.getItem("loginDetails")) {
      // @ts-ignore
      setLoginDetails(JSON.parse(sessionStorage.getItem("loginDetails")));
      setIsLoggedIn(true);

      if (
        // @ts-ignore
        JSON.parse(sessionStorage.getItem("loginDetails")).sessionExpire <=
        epochTime
      )
        resetLoginDetails();

      setTimeout(() => {
        resetLoginDetails();
        //@ts-ignore
      }, (JSON.parse(sessionStorage.getItem("loginDetails")).sessionExpire - epochTime - 30) * 1000);
    }
  }, [loginDetails, epochTime]);

  return (
    <Router>
      <Flex direction='row' height='100vh'>
        <Flex direction='column' flex='1'>
          <Header isLoggedIn={isLoggedIn} />
          <Box
            pl={{ base: "20px", md: "10px" }}
            pr={5}
            py={5}
            flex='1'
            overflowY='auto'
          >
            <Routes>
              <Route path='/' element={<Explore />} />
              <Route path='/subscriptions' element={<Subscriptions />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/edit-profile' element={<EditProfile />} />
              <Route path='/profile' element={<AccountManagement />} />
              <Route path='/messages' element={<Messages />} />
              <Route path='/notifications' element={<FullNotifications />} />
              <Route path='/purchase' element={<Purchase />} />
              <Route path='/explore' element={<Explore />} />
              <Route path='/Event/:id?' element={<EventPage />} />
              <Route
                path='/verifypasswordreset/:check'
                element={<ResetPassword />}
              />
              <Route path='/logout' element={<Logout />} />
              <Route path='*' element={<div>Not Found</div>} />
            </Routes>
          </Box>
        </Flex>
      </Flex>
    </Router>
  );
}

export default App;
