import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import MobileAppLinks from "./MobileAppLinks";

const CreateEventButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} variant='outline' colorScheme={"purple"}>
        Create an Event
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create an Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align='start'>
              <Text fontSize='lg' fontWeight='bold'>
                Want to host a paid event?
              </Text>
              <Text>
                Create and manage your paid events effortlessly with our
                merchant application. Get started now!
              </Text>
              <Button colorScheme='purple' as='a' href='https://merchant.tikroo.com/' width='100%'>
                Visit Merchant Portal
              </Button>

              <Text fontSize='lg' fontWeight='bold' mt={4}>
                Hosting a free event?
              </Text>
              <Text>
                Use our mobile application to create and manage your free events
                on the go. Download now!
              </Text>
              <MobileAppLinks />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateEventButton;
