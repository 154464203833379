import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { PersonFormData } from "../../../utilities/type-defs";

export default function FinalPreviewTicketItem(props: { index: number; item: PersonFormData; }) {
  return (
    <Box
      borderWidth='1px'
      p='3'
      mb='2'
      borderRadius='lg'
      borderColor='gray.300'
      borderStyle='solid'
      width='100%'
      boxShadow='0 0 10px rgba(0,0,0,0.1)'
      background='white'
    >
      <Heading mb={2} size='sm' textAlign='center' borderBottom='1px solid gray.300' pb={2}>
        Ticket Holder {props.index + 1}
      </Heading>
      <Flex direction='column' justify='space-between'>
        <Flex justify='space-between'>
          <Text width='30%'>Name:</Text>
          <Text width='70%' fontWeight='semibold'>
            {props.item.name}
          </Text>
        </Flex>
        <Flex justify='space-between' mt={2}>
          <Text width='30%'>Email:</Text>
          <Text width='70%' fontWeight='semibold'>
            {props.item.email}
          </Text>
        </Flex>
        <Flex justify='space-between' mt={2}>
          <Text width='30%'>Phone:</Text>
          <Text width='70%' fontWeight='semibold'>
            {props.item.phone}
          </Text>
        </Flex>
        <Flex justify='space-between' mt={2}>
          <Text width='30%'>Ticket Price:</Text>
          <Text width='70%' fontWeight='semibold'>
            ${parseInt(props.item.ticketType || "0").toFixed(2)}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
