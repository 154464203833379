import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface BreadcrumbItemProps {
  text: string;
  isCurrentPage?: boolean;
  to?: string;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItemProps[];
  spacing?: string;
  separator?: React.ReactElement;
  additionalProps?: any;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items,
  spacing = "8px",
  separator = <ChevronRightIcon color='gray.500' />,
  additionalProps,
}) => {
  return (
    <Breadcrumb spacing={spacing} separator={separator} {...additionalProps}>
      {items.map((item, index) => (
        <BreadcrumbItem key={index} isCurrentPage={item.isCurrentPage}>
          <BreadcrumbLink
            as={item.to ? RouterLink : undefined}
            to={item.to}
            onClick={item.onClick}
            href={item.href ? item.href : undefined}
            _hover={{ textDecor: "none", color: "blue.500" }}
            fontWeight='normal'
            px={2}
            borderRadius='md'
            display='flex'
            alignItems='center'
            cursor={index === items.length - 1 ? "default" : "pointer"}
          >
            <Box
              as='span'
              fontStyle={item.isCurrentPage ? "italic" : "normal"}
              color={item.isCurrentPage ? "blue.500" : "inherit"}
            >
              {item.text}
            </Box>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
