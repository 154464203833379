import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import LoginForm from "../pages/Auth/Login/LoginForm";
import RegisterForm from "../pages/Auth/Register/RegisterForm";
import useAuthModals from "../pages/Auth/useAuthModals";
import { WithSubNavigationProps } from "../utilities/type-defs";
import CreateEventButton from "./CreateEventButton";
import { DesktopNav } from "./DesktopNav";
import Messages from "./Messages";
export default function WithSubnavigation({
  isLoggedIn,
}: WithSubNavigationProps) {
  const { register, login, forgotPassword } = useAuthModals();
  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        justify={{ base: "space-between", md: "start" }}
      >
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "flex-start", md: "start" }}
          align='center'
        >
          <RouterLink to='/explore'>
            <Image
              src={`${process.env.PUBLIC_URL}/logo512.png`}
              alt='Logo'
              height='50px'
              objectFit='contain'
              color={useColorModeValue("gray.800", "white")}
            />
          </RouterLink>
          <Flex display={{ base: "none", md: "flex" }} ml={10} align='center'>
            <DesktopNav navItems={[]} />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          {isLoggedIn ? (
            <>
              <CreateEventButton />
              <SignedNavMenu />
            </>
          ) : (
            <>
              <CreateEventButton />
              <UnsignedNavMenu
                login={login}
                register={register}
                forgotPassword={forgotPassword}
              />
            </>
          )}
        </Stack>
      </Flex>
    </Box>
  );
}

const SignedNavMenu = () => {
  const messageModal = useDisclosure();
  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rounded={"full"}
          variant={"link"}
          cursor={"pointer"}
        >
          <Avatar size={"sm"} />
        </MenuButton>
        <MenuList>
          <MenuItem as={RouterLink} to='/explore'>
            Explore
          </MenuItem>
          <MenuItem as={RouterLink} to='/subscriptions'>
            Subscriptions
          </MenuItem>
          <MenuItem as={RouterLink} to='/profile'>
            Profile
          </MenuItem>
          <MenuItem onClick={messageModal.onOpen}>Messages</MenuItem>
          <MenuItem as={RouterLink} to='/logout'>
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
      <Messages
        isOpen={messageModal.isOpen}
        onClose={messageModal.onClose}
        onOpen={messageModal.onOpen}
      />
    </>
  );
};

const UnsignedNavMenu = ({ login, register, forgotPassword }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {/* Menu for mobile view */}
      {isMobile && (
        <Menu>
        <MenuButton
          as={IconButton}
          icon={<HamburgerIcon />}
          variant='outline'
          aria-label='Options'
          rounded={"full"}
        />
          <MenuList>
            <MenuItem as='button' onClick={login.open}>
              Login
            </MenuItem>
            <LoginForm
              login={login}
              register={register}
              forgotPassword={forgotPassword}
            />
            <MenuItem as='button' onClick={register.open}>
              Register
            </MenuItem>
            <RegisterForm
              login={login}
              register={register}
              forgotPassword={forgotPassword}
            />
          </MenuList>
        </Menu>
      )}

      {/* Buttons for desktop view */}
      {!isMobile && (
        <Stack direction={"row"} spacing={4}>
          <Button variant='outline' onClick={login.open} colorScheme='blue'>
            Login
          </Button>
          <LoginForm
            login={login}
            register={register}
            forgotPassword={forgotPassword}
          />
          <Button variant='outline' onClick={register.open} colorScheme='blue'>
            Register
          </Button>
          <RegisterForm
            login={login}
            register={register}
            forgotPassword={forgotPassword}
          />
        </Stack>
      )}
    </>
  );
};
