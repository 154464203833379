import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const Messages = ({ isOpen, onClose, onOpen }) => {
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("white", "gray.800")}>
          <ModalHeader color={useColorModeValue("black", "white")}>
            Unlock a World of Features
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Text
                mb={4}
                fontSize='lg'
                color={useColorModeValue("gray.700", "gray.300")}
              >
                Download our app to access messages and enjoy a seamless
                experience, exclusive features, and more!
              </Text>
            </Center>

            <Stack align={"center"}>
              <Center>
                <Stack>
                  <a
                    rel='noreferrer'
                    target={"_blank"}
                    href='https://play.google.com/store/apps/details?id=com.synergize.synergy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  >
                    <img
                      className={"google-image"}
                      alt='Get it on Google Play'
                      src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                    />
                  </a>
                </Stack>

                <Stack>
                  <a
                    rel='noreferrer'
                    target={"_blank"}
                    href='https://apps.apple.com/au/app/synergy/id6444486910'
                  >
                    <img
                      className={"apple-image"}
                      alt='Get it on the App store'
                      src='/images/apple.svg'
                    />
                  </a>
                </Stack>
              </Center>
            </Stack>
          </ModalBody>
          <ModalFooter bg={useColorModeValue("gray.50", "gray.700")}>
            <Button colorScheme='blue' onClick={onClose}>
              Got it!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Messages;
