import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  VStack,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { FaUserAlt } from 'react-icons/fa';
export default function EditProfile() {
  const location = useLocation();
  const { user } = location.state;

  //TODO if no user go to /explore

  const theme = useTheme();
  const bgColor = useColorModeValue("white", "gray.900");
  return (
    <div>
      <Container maxW='container.xl' py={12}>
      <Box pl='10%' pr='10%' mb={10}>
          <Heading fontSize='xl' textAlign='center'>Edit Profile</Heading>
        </Box>
        <Center>
          <Box
          maxW={"400px"}
          w={"full"}
          bg={bgColor}
          boxShadow={"2xl"}
          rounded={"lg"}
          p={6}
          border='1px'
          borderColor={theme.colors.gray[200]}
          textAlign={"center"}
          >
            <VStack spacing={5}>
              <Center p='20px'>
                <Avatar size='2xl' name={user.fname}  />
              </Center>
              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  type='text'
                  name='name'
                  placeholder='First Name'
                  defaultValue={user.fname}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type='text'
                  name='name'
                  placeholder='Last Name'
                  defaultValue={user.sname}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Number</FormLabel>
                <Input
                  type='text'
                  name='name'
                  placeholder='Mobile Number'
                  defaultValue={user.mobile}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type='email'
                  name='email'
                  placeholder='Email Address'
                  defaultValue={user.email}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Description</FormLabel>
                <Textarea
                  name='message'
                  placeholder='Profile description'
                  rows={6}
                  resize='none'
                  defaultValue={user.desc}
                />
              </FormControl>
              <Stack mt={8} direction={"row"} spacing={4} width='full'>
                <RouterLink to='/profile'>
                  <Button flex={1} fontSize={"sm"} _focus={{ bg: "gray.200" }}>
                    Cancel
                  </Button>
                </RouterLink>
                <Button
                  flex={1}
                  fontSize={"sm"}
                  bg={"purple.400"}
                  color={"white"}
                  _hover={{ bg: "purple.500" }}
                  _focus={{ bg: "purple.500" }}
                >
                  Confirm
                </Button>
              </Stack>
            </VStack>
          </Box>
        </Center>
      </Container>
    </div>
  );
}
