import { gql } from "@apollo/client";
import { loginSessionData } from "./type-defs";

const GET_USERS = gql`
  query GetUsers {
    users {
      _id
      fname
      sname
      email
      type
    }
  }
`;

const GET_USER = gql`
  query user($userId: String!) {
    userById(userId: $userId) {
      fname
      sname
      email
      type
      profileImg
      mobile
      desc
    }
  }
`;

const SIGN_IN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      userId
      userType
      token
      tokenExpiration
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($user: UserInput!) {
    createUser(userInput: $user) {
      _id
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation forgotUserPassword($email: String!) {
    forgotUserPassword(email: $email)
  }
`;

const GET_USER_BY_VERIFICATION_TOKEN = gql`
  query getUserByVerificationToken($verificationToken: String!) {
    getUserByVerificationToken(verificationToken: $verificationToken) {
      _id
    }
  }
`;

const UPDATE_USER_PASSWORD_BY_VERIFICATION_TOKEN = gql`
  mutation updateUserPasswordByVerificationToken(
    $verificationToken: String!
    $password: String!
  ) {
    updateUserPasswordByVerificationToken(
      verificationToken: $verificationToken
      password: $password
    )
  }
`;

const AUTHORIZATION_BEARER = () => {
  let _loginSession: string | null = sessionStorage.getItem("loginDetails");

  if (_loginSession === null) return { error: "ERROR" };

  let loginSession: loginSessionData = JSON.parse(_loginSession);
  let Authorization: string = `Bearer ${loginSession.token}`;

  return {
    headers: {
      Authorization: Authorization,
    },
  };
};

const GET_USER_ID_FROM_LOGIN = () => {
  let _loginSession: string | null = sessionStorage.getItem("loginDetails");

  if (_loginSession === null) return null;

  let loginSession: loginSessionData = JSON.parse(_loginSession);
  return loginSession.userId;
};

export {
  AUTHORIZATION_BEARER,
  CREATE_USER,
  FORGOT_PASSWORD,
  GET_USER,
  GET_USERS,
  GET_USER_BY_VERIFICATION_TOKEN,
  GET_USER_ID_FROM_LOGIN,
  SIGN_IN,
  UPDATE_USER_PASSWORD_BY_VERIFICATION_TOKEN,
};
