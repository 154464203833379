import { gql } from "@apollo/client";

const SEND_EMAIL =
gql`
    query SendEmail($to: String!, $subject: String!, $message: String!) {
        sendEmail(to: $to, subject: $subject, message: $message)
    }
`

export { SEND_EMAIL };
