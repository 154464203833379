import { useMutation, useQuery } from "@apollo/client";
import { CheckCircleIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import {
  GET_USER_BY_VERIFICATION_TOKEN,
  UPDATE_USER_PASSWORD_BY_VERIFICATION_TOKEN,
} from "../../../utilities/users-gql";

/* FORM DATA */
type FormData = {
  password: string;
  confirm_password?: string;
};

function ResetPassword() {
  //first, check the code and get the user information.
  const { check } = useParams();

  /* Check the code to see if it's invalid... */
  const getUsersQuery = useQuery(GET_USER_BY_VERIFICATION_TOKEN, {
    variables: { verificationToken: check },
  });

  /* Show the loading views / Error Views */
  if (getUsersQuery.loading) return <>Loading...</>;
  if (getUsersQuery.error || check === "undefined")
    return (
      <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.250"}>
        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
          my={12}
          bg={"grey.50"}
        >
          <Center>
            <Alert status='error' textAlign={"center"} rounded={"xl"}>
              <AlertIcon />
              <AlertTitle>Invalid code</AlertTitle>
              <AlertDescription>The code given is invalid</AlertDescription>
            </Alert>
          </Center>
          <Center>
            <Button colorScheme={"teal"} size={"md"} maxW={"xs"}>
              <Link to={"/login"}>Return to Login</Link>
            </Button>
          </Center>
        </Stack>
      </Flex>
    );

  return <ChangePasswordBox />;
}

function ChangePasswordBox(): JSX.Element {
  const [updateUserMutationQuery] = useMutation(
    UPDATE_USER_PASSWORD_BY_VERIFICATION_TOKEN
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState("");

  const { check } = useParams();

  const handleChangePassword = async (data: FormData) => {
    console.log(data);

    await updateUserMutationQuery({
      variables: { verificationToken: check, password: data.password },
    })
      .then(() => {
        setChangePasswordSuccess(true);
        setChangePasswordError("");
      })
      .catch((e) => {
        console.log(e);
        setChangePasswordSuccess(false);
        setChangePasswordError("Error");
      });

    return <>{check}</>;
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  /* Form Controllers */
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  /* Add a hook to watch the password state for watching validation*/
  let pwd = watch("password");

  return (
    <div>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
          my={12}
        >
          {!!changePasswordSuccess ? (
            <Alert status='success' textAlign={"center"} rounded={"xl"}>
              <CheckCircleIcon color={"green"} mr={3} />
              <AlertDescription>
                Your password has been changed successfully!
              </AlertDescription>
            </Alert>
          ) : changePasswordError !== "" ? (
            <Alert status='error' textAlign={"center"} rounded={"xl"}>
              <AlertIcon mr={3} />
              <AlertDescription>
                There was an error changing your password
              </AlertDescription>
            </Alert>
          ) : (
            ""
          )}

          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            Enter new password
          </Heading>
          <Controller
            control={control}
            name={"password"}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormControl isInvalid={!!errors.password} isRequired>
                <FormLabel>Password:</FormLabel>
                <InputGroup>
                  <Input
                    id={"password"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder={"●●●●●●●●●●●●"}
                    type={showPassword ? "text" : "password"}
                  />
                  <InputRightElement width='3rem'>
                    <Button
                      h='1.5rem'
                      size='sm'
                      onClick={handlePasswordVisibility}
                    >
                      {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {!!errors.password ? (
                  <FormErrorMessage>
                    A valid password is required
                  </FormErrorMessage>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            rules={{
              required: true,
              minLength: 5,
            }}
          />

          <Controller
            control={control}
            name={"confirm_password"}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormControl isInvalid={!!errors.confirm_password} isRequired>
                <FormLabel>Confirm Password:</FormLabel>
                <InputGroup>
                  <Input
                    id={"confirm_password"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder={"●●●●●●●●●●●●"}
                    type={showConfirmPassword ? "text" : "password"}
                  />
                  <InputRightElement width='3rem'>
                    <Button
                      h='1.5rem'
                      size='sm'
                      onClick={handleConfirmPasswordVisibility}
                    >
                      {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {!!errors.confirm_password ? (
                  <FormErrorMessage>
                    A valid password is required
                  </FormErrorMessage>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            rules={{
              required: true,
              validate: (value) =>
                value === pwd || "The passwords do not match",
            }}
          />

          <Stack spacing={6}>
            <Button
              colorScheme='teal'
              onClick={handleSubmit(handleChangePassword)}
            >
              {"Update Password"}
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </div>
  );
}

export default ResetPassword;
